import { TCompanyRole } from "../network";

export type TCompany = {
  id: string;
  name: string;
  isActive: boolean;
  purpose: string;
  picture: string;
  complianceEmail: string;
};

export type TCompanyWithRole = {
  id: string;
  name: string;
  isActive: boolean;
  purpose: string;
  picture: string;
  complianceEmail: string;
  role: "admin" | "viewer";
};

export type TOfficer = {
  id: string;
  email: string;
  phone: string;
  companyRole: TCompanyRole[];
};

export type IS3UploadRequest = {
  key: string;
  url: string;
};
export interface IApplicationKeys {
  apiOrigin: string;
  widgetUrl: string;
  publicKey: string;
  appPublicKey: string;
  appToken: string;
  webhookSecretKey: string;
}
export interface IGeneratedKeys extends IApplicationKeys {
  privateKey: string;
}
export interface IViewKeys extends IApplicationKeys {
  webhookUrl: string;
}
export interface ICreateCoutryRegex {
  country: string;
  type: string;
  regexp: string;
  example: string;
  mask?: string;
}
export interface ICountryRegex extends ICreateCoutryRegex {
  id: string;
  createdAt: string;
  updatedAt: string;
}

export const AUTH_COMPANY_ID = "company_id";
export const AUTH_COMPANY_NAME = "company_name";
export const AUTH_COMPANY_PURPOSE = "company_purpose";
export const AUTH_COMPLIANCE_EMAIL = "compliance_email";

export type ICompanyActionModeType = "create" | "edit" | "";
export type IOfficerActionModeType = "create" | "edit" | "onlyEdit";
