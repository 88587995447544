import { memo, useMemo, useState } from "react";
import {
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { TDocumentData, TDocumentInfo, TUserFullInfo } from "../../models";
import { BinaryContent } from "../BinaryContent";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { DocumentAccordeon } from "./styles";
import { DocumentImgCard } from "../DocumentImgCard";
import { titleCase } from "../../../../utils";
import { mainTheme } from "../../../../theme/mainTheme";

export * from "./types";

export type Props = {
  document: TDocumentInfo;
  userInfo: TUserFullInfo;
  data: TDocumentData[];
  requestId: string;
  companyRole: string;
  initialComment: string;
  isMobile: boolean;
  addComment: (userDocumentId: string, comment: string) => void;
};

export const DocumentInfoCard = memo((props: Props) => {
  const {
    requestId,
    initialComment,
    addComment,
    document,
    data,
    userInfo,
    isMobile,
    companyRole,
  } = props;
  const title = document.documentType;
  const [dataFields, setDataFields] = useState({});
  const [comment, setComment] = useState(initialComment);
  const [binaryItems, fileItems] = useMemo(() => {
    const binary: typeof data = [];
    const file: typeof data = [];
    data.forEach((x) => {
      if (x.type === "binary") {
        binary.push(x);
      } else if (x.type === "file") {
        file.push(x);
      }
    });

    return [binary, file];
  }, [data]);

  return (
    <DocumentAccordeon
      sx={{
        backgroundColor: document.declineReason.length
          ? "rgba(0, 0, 0, 0.04)"
          : "",
      }}
      disableGutters
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls='panel1a-content'
        id='panel1a-header'
      >
        <Grid container alignItems='center' spacing={3}>
          <Grid item>
            <Typography
              variant='h6'
              sx={{
                fontWeight: "800",
                fontSize: "20px",
                lineHeight: "28px",
                letterSpacing: "0.5px",
                color: "#1C1F21",
                textTransform: "capitalize",
              }}
            >
              {titleCase(title)}
            </Typography>
          </Grid>
          {/* {binaryData && !isObjectsEqual(binaryData!, dataFields) ? (
            <Grid item>
              <Button color='error' onClick={(e) => onSubmit(e)}>
                Save
              </Button>
            </Grid>
          ) : null} */}
          {/* {error !== null && (
            <Grid item>
              <Alert severity='error'>{error.toString()}</Alert>
            </Grid>
          )} */}
        </Grid>
      </AccordionSummary>
      <AccordionDetails sx={{ marginTop: "8px" }}>
        {document.declineReason.length ? (
          <TextField
            sx={{
              marginBottom: 4,
              input: { color: mainTheme.palette.error.main },
            }}
            fullWidth
            value={document.declineReason.join(", ")}
            name='declineReason'
            label='Decline reason(s)'
            inputProps={{
              disabled: true,
            }}
          />
        ) : null}
        {title === "UserInformation" ? (
          <Stack
            direction={isMobile ? "column" : "row"}
            spacing={2}
            sx={{
              marginBottom: 8,
            }}
          >
            <TextField
              fullWidth
              value={userInfo.phone}
              name='phone'
              label='Phone number'
              inputProps={{
                disabled: true,
              }}
            />
            <TextField
              fullWidth
              value={userInfo.email}
              name='email'
              label='Email'
              inputProps={{
                disabled: true,
              }}
            />
            <TextField
              fullWidth
              value={userInfo.externalEmail}
              name='externalEmail'
              label='External system email'
              inputProps={{
                disabled: true,
              }}
            />
          </Stack>
        ) : null}
        {binaryItems.map((dataItem) => (
          <BinaryContent
            allDisabled
            dataFields={dataFields}
            setDataFields={setDataFields}
            data={dataItem}
          />
        ))}
        {fileItems.length > 0 && (
          <DocumentImgCard
            fileItems={fileItems}
            requestId={requestId}
            document={document}
          />
        )}
        <Stack spacing={2} sx={{ mt: 4 }} direction='row'>
          <Stack flex={1}>
            <TextField
              fullWidth
              multiline={true}
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              label={"Compliance comment"}
              disabled={companyRole !== "admin"}
            />
          </Stack>
          <Stack justifyContent='center' flexShrink={0}>
            <Button
              disabled={initialComment === comment}
              onClick={() => addComment(document.documentId, comment)}
            >
              Save
            </Button>
          </Stack>
        </Stack>
      </AccordionDetails>
    </DocumentAccordeon>
  );
});
