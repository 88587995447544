import { humanize, underscore } from "inflection";

export const titleCase = (str: string): string => {
  if (typeof str !== "string") {
    return str;
  }
  const allUpper = str.toUpperCase() === str;

  if (str === "first_name") {
    str += "(s)";
  }
  return humanize(underscore(str, allUpper));
};

export const getMonolithString = (
  str: string | undefined,
  allLower?: boolean
) => {
  if (typeof str !== "string") {
    return str;
  }
  return (allLower ? str.toLowerCase() : str).replace(" ", "");
};
