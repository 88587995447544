import { getSortAscending } from "./../../verificationRequest/utils/getSort";
import { TokenData } from "../../../auth/types";
import { TPagingResponse } from "../../../models/PagingResponse";
import { request } from "../../../network/mainClient/request";
import { TApp } from "../../app/models";
import {
  IGeneratedKeys,
  IS3UploadRequest,
  TCompany,
  TOfficer,
} from "../models";

export const getCompaniesList = async (): Promise<
  TPagingResponse<TCompany>
> => {
  const response = await request({
    path: "/api/v1/company-service",
    method: "GET",
  });

  if (response.ok) {
    return {
      items: response.data.companies,
    };
  }

  throw new Error(response.errorMessage);
};

export const getS3UploadUrl = async (
  fileType: string,
  fileSize: number
): Promise<IS3UploadRequest> => {
  const response = await request({
    path: "/api/v1/company-service/upload-url",
    method: "POST",
    body: {
      fileSize,
      fileType,
    },
  });

  if (response.ok) {
    return response.data;
  }

  throw new Error(response.errorMessage);
};

export const uploadToS3 = async (
  url: string,
  blob: Blob,
  contentType: string
) => {
  const response = await request({
    withoutBaseUrl: true,
    path: url,
    method: "PUT",
    body: blob,
    withAuth: false,
    contentType: contentType,
  });

  if (response.ok) {
    return response.data;
  }

  throw new Error(response.errorMessage);
};

export const authToCompany = async (id: string): Promise<TokenData> => {
  const response = await request({
    path: "/api/v1/company-service/auth",
    method: "POST",
    body: {
      id,
    },
  });

  if (response.ok && response.data?.token) {
    return response.data.token;
  }

  throw new Error(response.errorMessage);
};

export const getCompany = async (id: string): Promise<TCompany> => {
  const response = await request({
    path: `/api/v1/company-service/${id}`,
    method: "GET",
  });

  if (response.ok && response.data) {
    return response.data.company;
  }

  throw new Error(response.errorMessage);
};

export type TCreateCompanyData = {
  name: string;
  purpose: string;
  isActive: boolean;
  picture?: string;
  complianceEmail?: string;
  id?: string;
};

export const createCompany = async (
  data: TCreateCompanyData
): Promise<TCompany> => {
  const response = await request({
    path: "/api/v1/company-service/create",
    method: "POST",
    body: data,
  });

  if (response.ok) {
    return response.data.company;
  }

  throw new Error(response.errorMessage);
};

export const updateCompany = async (
  data: TCreateCompanyData
): Promise<TCompany> => {
  const response = await request({
    path: "/api/v1/company-service/update",
    method: "PATCH",
    body: data,
  });

  if (response.ok) {
    return response.data.company;
  }

  throw new Error(response.errorMessage);
};

export type TGenerateKeys = {
  webHookUrl: string;
  applicationName: string;
};

export const generateKeys = async (
  data: TGenerateKeys
): Promise<IGeneratedKeys> => {
  const response = await request({
    path: "/api/v1/user-service/generate-keys",
    method: "POST",
    body: data,
  });

  if (response.ok) {
    return response.data.keys;
  }

  throw new Error(response.errorMessage);
};

export const getApplicationsList = async (): Promise<TApp[]> => {
  const response = await request({
    path: "/api/v1/user-service/app/list",
    method: "GET",
  });

  if (response.ok) {
    return response.data.apps;
  }

  throw new Error(response.errorMessage);
};

export type TCompanyRole = {
  companyId: string;
  role: "admin" | "viewer";
};

export type TCreateOfficerData = {
  phone: string;
  email: string;
  companyRole: TCompanyRole[];
};

export const changeUserIdToId = (user: any) => {
  return {
    ...user,
    id: user.userId,
  };
};

export const getOfficersList = async (): Promise<TPagingResponse<TOfficer>> => {
  const response = await request({
    path: `/api/v1/user-service/company/user`,
    method: "GET",
  });

  if (response.ok) {
    return {
      items: getSortAscending(
        response.data.companyUser.map((user: any) => changeUserIdToId(user)),
        "email"
      ),
    };
  }

  throw new Error(response.errorMessage);
};

export const createOfficer = async (
  data: TCreateOfficerData
): Promise<TOfficer> => {
  const response = await request({
    path: `/api/v1/user-service/company/user`,
    method: "POST",
    body: data,
  });

  if (response.ok) {
    return response.data.companyUser;
  }

  throw new Error(response.errorMessage);
};

export const updateOfficer = async (
  userId: string,
  data: TCreateOfficerData
): Promise<TOfficer> => {
  const response = await request({
    path: `/api/v1/user-service/company/user/${userId}`,
    method: "PATCH",
    body: data,
  });

  if (response.ok) {
    return response.data.companyUser;
  }

  throw new Error(response.errorMessage);
};

export const checkOfficerPhone = async (phone: string): Promise<TOfficer> => {
  const response = await request({
    path: `/api/v1/user-service/company/user/phone/${phone}`,
    method: "GET",
  });

  if (response.ok) {
    return response.data.companyUser;
  }

  throw new Error(response.errorMessage);
};
