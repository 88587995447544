import {
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Box,
  ListItemIcon,
  Stack,
  Typography,
  CircularProgress,
} from "@mui/material";
import {
  BanIcon,
  RejectIcon,
  ReloadIcon,
} from "../../../../components/MuiIcons";
import { PanelLayout } from "../PanelLayout";
import { FC } from "react";
import { ActionButton } from "./styles";
import SendIcon from "@mui/icons-material/Send";
import { mainTheme } from "../../../../theme/mainTheme";
import { useAuth } from "../../../../auth/useAuth";

const getActions: (
  status: string,
  statusReason?: string
) => {
  id: number;
  label: string;
  color: string;
  icon: React.ReactNode;
  subtitle: string;
  actionValue?: string;
}[] = (status, statusReason = "") => {
  const actions = [
    status === "NEW"
      ? {
          id: 0,
          label: "Send to Verification",
          subtitle: "If the user forgot to send",
          color: mainTheme.palette.primary.main,
          icon: <SendIcon color='primary' />,
          actionValue: "sendToVerify",
        }
      : {
          id: 0,
          label: "Request information",
          subtitle: "If something is lost or missed",
          color: mainTheme.palette.primary.main,
          icon: <ReloadIcon />,
          actionValue: "requestInfo",
        },
    {
      id: 1,
      label: "Blacklist",
      subtitle: "If something is unacceptable",
      color: "#1C1F21",
      icon: <BanIcon />,
    },
  ];
  if (status === "CANCELED") {
    actions.push({
      id: 2,
      label: "Reason for rejection",
      subtitle: statusReason,
      color: mainTheme.palette.error.main,
      icon: <RejectIcon fill={mainTheme.palette.error.main} />,
      actionValue: "changeRejectReason",
    });
  }
  return actions;
};

export const ActionPanel: FC<{
  onAction: (value: string) => void;
  status: string;
  loading: boolean;
  companyRole: string;
  statusReason?: string;
}> = ({ onAction, status, statusReason, companyRole, loading }) => {
  return (
    <PanelLayout title='Actions'>
      <List>
        {getActions(status, statusReason).map(
          ({ id, label, color, icon, subtitle, actionValue }, index) => (
            <>
              <ListItem
                disablePadding
                key={id}
                sx={{
                  margin: "8px 0",
                  borderBottom: "1px solid #F4F4F4",
                }}
              >
                {index === 0 && loading ? (
                  <CircularProgress style={{ marginBottom: 14 }} size={30} />
                ) : (
                  <ListItemButton
                    sx={{ margin: "0", padding: "0", flexWrap: "wrap" }}
                    onClick={() => actionValue && onAction(actionValue)}
                    disabled={companyRole !== "admin"}
                  >
                    <ListItemIcon sx={{ minWidth: "0", marginRight: "10px" }}>
                      {icon}
                    </ListItemIcon>
                    <ListItemText
                      primary={label}
                      primaryTypographyProps={{
                        color: color,
                        fontWeight: "700",
                        fontSize: "14px",
                        lineHeight: "22px",
                        letterSpacing: "0.5px",
                      }}
                    />
                    <Typography fontSize={"14px"} mb='1em' width='100%'>
                      {subtitle}
                    </Typography>
                  </ListItemButton>
                )}
              </ListItem>
            </>
          )
        )}
      </List>
      <Stack
        flexDirection='row'
        alignItems='center'
        justifyContent='flex-end'
        width='100%'
        height='100%'
        gap='16px'
      >
        <Box flexGrow={1}>
          <ActionButton
            variant='contained'
            color='error'
            onClick={() => onAction("decline")}
            disabled={
              status === "NEW" ||
              status === "CANCELED" ||
              companyRole !== "admin"
            }
          >
            Reject
          </ActionButton>
        </Box>
        <Box flexGrow={1}>
          <ActionButton
            disabled={
              status === "NEW" ||
              status === "ACCEPTED" ||
              companyRole !== "admin"
            }
            variant='contained'
            color='success'
            onClick={() => onAction("accept")}
          >
            Accept
          </ActionButton>
        </Box>
      </Stack>
    </PanelLayout>
  );
};
