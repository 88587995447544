import { useCallback, useEffect, useState } from "react";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import { Alert, Box, Stack, Typography } from "@mui/material";
import {
  DialogCustomButton,
  DialogCustomContent,
  DialogCustomTitle,
  StyledTextarea,
} from "./styles";
import { useVerificationRequestInformation } from "../../hooks/useVerificationRequestInformation";
import { WIDGET_URL } from "../../../../constants";
import {
  AUTH_COMPANY_NAME,
  AUTH_COMPLIANCE_EMAIL,
} from "../../../company/models";
import { getMonolithString } from "../../../../utils";

const getTextMessage = (data: any) => {
  const complianceEmail =
    localStorage.getItem(AUTH_COMPLIANCE_EMAIL) || "compliance@oneboard.li";
  const companyMonolithName = getMonolithString(
    localStorage.getItem(AUTH_COMPANY_NAME) as string,
    true
  );
  return `Dear ${data?.additionalItems?.userInformationParsed?.first_name},

Thank you for registering!

It appears that your KYC process is not finalized.

Please follow the link below to change your data: ${WIDGET_URL}/${companyMonolithName}/?appPublicKey=${data?.appPublicKey}&verificationRequestId=${data?.id}

Please don't hesitate to contact us on ${complianceEmail} - we will be happy to assist!

Best regards,
Oneboard team`;
};

export type Props = {
  onClose: (value: "requestInfo" | "cancel") => void;
  open: boolean;
  documentId: string;
  data: any;
};

export const DocumentRequestInfoDialog = (props: Props) => {
  const { documentId, onClose, open, data } = props;
  const [message, setMessage] = useState("");
  const [result, loading, error, request] = useVerificationRequestInformation();

  useEffect(() => {
    if (result) {
      onClose("requestInfo");
    }
  }, [onClose, result]);

  const handleCancel = useCallback(() => {
    onClose("cancel");
  }, [onClose]);

  const handleOk = useCallback(() => {
    const messageReplaced = message.replace(/\n/g, "<br />");
    request(documentId, messageReplaced);
  }, [request, documentId, message]);

  useEffect(() => {
    setMessage(getTextMessage(data));
  }, [data]);

  return (
    <Dialog
      sx={{
        "& .MuiDialog-paper": {
          width: "450px",
          borderRadius: "16px",
        },
      }}
      maxWidth='xs'
      open={open}
      id='document-review-dialog'
      keepMounted
    >
      <DialogCustomTitle>Message to client</DialogCustomTitle>
      <DialogCustomContent>
        Please specify here what the client needs to correct or add
        <StyledTextarea
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          aria-label='minimum height'
          minRows={3}
          placeholder='Type something'
        ></StyledTextarea>
        <Typography sx={{ fontSize: "0.8em", textAlign: "justify", mt: 2 }}>
          If Selfie or Passport sections are not filled or missing - please
          instruct the client:
          <br />
          Identification section (i.e. the ID and selfie verification) has not
          been completed. If the computer camera does not capture a sufficiently
          clear image, a possible solution is to finalize the KYC on your phone.
        </Typography>
        {error !== null && <Alert severity='error'>{error.toString()}</Alert>}
      </DialogCustomContent>
      <DialogActions sx={{ padding: "0 32px 32px" }}>
        <Stack
          flexDirection='row'
          alignItems='center'
          justifyContent='space-between'
          width='100%'
        >
          <Box>
            <DialogCustomButton
              autoFocus
              onClick={handleCancel}
              disabled={loading}
              variant='outlined'
            >
              Cancel
            </DialogCustomButton>
          </Box>
          <Box>
            <DialogCustomButton
              onClick={handleOk}
              disabled={loading}
              variant='contained'
              color='primary'
            >
              Send
            </DialogCustomButton>
          </Box>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};
