import qs from "qs";
import { authStorage } from "../../auth/authStorage";
import { API_BASE_URL } from "../../constants";

export type ReqestOption = {
  path: string;
  baseUrl?: string;
  withoutBaseUrl?: boolean;
  method?: "GET" | "POST" | "PUT" | "PATCH" | "DELETE";
  headers?: {
    [key: string]: string;
  };
  body?: any;
  contentType?: "json" | any;
  params?: {
    [key: string]: string;
  };
  withAuth?: boolean;
};

export type RequestResponse = {
  ok: boolean;
  statusCode: number;
  headers: Headers;
  data: any;
  errorMessage?: string;
};

export const redirectToAuth = () => {
  let joinURL = `${window.location.protocol}//${window.location.host}/auth`;
  const currentURL = window.location.href;
  const excludePathURL = ["/auth"];
  const isNotExcludeURL = !excludePathURL.filter((path) =>
    Boolean(currentURL.match(path))
  ).length;

  if (isNotExcludeURL) {
    window.location.href = joinURL;
  }
};

const ERROR_MESSAGES = {
  400: "Bad Request",
  401: "Unauthorized",
  402: "Payment Required",
  403: "Forbidden",
  404: "Not Found",
  405: "Method Not Allowed",
  406: "Not Acceptable",
  407: "Proxy Authentication Required",
  408: "Request Timeout",
  409: "Conflict",
  410: "Gone",
  411: "Length Required",
  412: "Precondition Failed",
  413: "Payload Too Large",
  414: "URI Too Long",
  415: "Unsupported Media Type",
  416: "Range Not Satisfiable",
  417: "Expectation Failed",
  418: "I'm a teapot",
  419: "Authentication Timeout (not in RFC 2616)",
  421: "Misdirected Request [10];",
  422: "Unprocessable Entity",
  423: "Locked",
  424: "Failed Dependency",
  425: "Too Early",
  426: "Upgrade Required",
  428: "Precondition Required",
  429: "Too Many Requests",
  431: "Request Header Fields Too Large",
  449: "Retry With",
  451: "Unavailable For Legal Reasons",
  499: "Client Closed Request (клиент закрыл соединение);",
  500: "Internal Server Error",
  501: "Not Implemented",
  502: "Bad Gateway",
  503: "Service Unavailable",
  504: "Gateway Timeout",
  505: "HTTP Version Not Supported",
  506: "Variant Also Negotiates",
  507: "Insufficient Storage",
  508: "Loop Detected",
  509: "Bandwidth Limit Exceeded",
  510: "Not Extended",
  511: "Network Authentication Required",
  520: "Unknown Error",
  521: "Web Server Is Down",
  522: "Connection Timed Out",
  523: "Origin Is Unreachable",
  524: "A Timeout Occurred",
  525: "SSL Handshake Failed",
  526: "Invalid SSL Certificate ",
};

export const request = async (options: ReqestOption) => {
  let {
    baseUrl = API_BASE_URL,
    withoutBaseUrl = false,
    path,
    method = "GET",
    headers,
    body,
    contentType = "json",
    params,
    withAuth = true,
  } = options;

  let requestBody = null;
  if (body && contentType === "json") {
    requestBody = JSON.stringify(body);
    headers = {
      ...headers,
      "Content-Type": "application/json",
    };
  } else if (body && contentType) {
    requestBody = body;
    headers = {
      ...headers,
      "Content-Type": contentType,
    };
  }

  let url = path;
  if (!withoutBaseUrl && baseUrl) {
    url = baseUrl + url;
  }
  if (params) {
    url = url + qs.stringify(params);
  }

  if (withAuth) {
    const token = await authStorage.getAuthData();
    if (token) {
      headers = {
        ...headers,
        Authorization: `Bearer ${token.accessToken}`,
      };
    }
  }

  const requestData: RequestInit = {
    headers,
    method,
    body: requestBody,
  };
  const response = await fetch(url, requestData);

  const result: RequestResponse = {
    ok: response.ok,
    statusCode: response.status,
    headers: response.headers,
    data: null,
  };

  const bodyType = result.headers.get("content-type");

  if (bodyType?.includes("application/json")) {
    result.data = await response.json();
  }

  if (!result.ok) {
    if (result.data) {
      if (result.data.message) {
        result.errorMessage = result.data.message;
      } else {
        result.errorMessage = JSON.stringify(result.data);
      }
    } else if (result.statusCode in ERROR_MESSAGES) {
      if (result.statusCode === 401) {
        await authStorage.clear();
        redirectToAuth();
      }
      // @ts-ignore
      result.errorMessage = ERROR_MESSAGES[result.statusCode];
    } else {
      result.errorMessage = "Unknown error";
    }
  }

  return result;
};
