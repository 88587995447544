import { ApprovedIcon, PendingIcon } from "../../components/MuiIcons";
import { mainTheme } from "../../theme/mainTheme";
import { getSortAscending } from "../verificationRequest/utils/getSort";
import { TCompany, TCompanyWithRole } from "./models";
import { TCompanyRole } from "./network";

export const statusIcons: {
  [key: string]: any;
} = {
  false: PendingIcon,
  true: ApprovedIcon,
};

export const statusesModel: {
  [key: string]: { bgColor: string; name: string; textColor: string };
} = {
  false: {
    bgColor: "#FFD70333",
    name: "Inactive",
    textColor: mainTheme.palette.warning.main,
  },
  true: {
    bgColor: `${mainTheme.palette.success.main}1A`,
    name: "Active",
    textColor: mainTheme.palette.success.main,
  },
};

export const getStatusIcon = (
  documentStasus: boolean,
  props: any = {}
): JSX.Element => {
  const Icon = statusIcons[String(documentStasus)];
  return <Icon {...props} />;
};

export const getCompaniesNameFromIds = (
  companies: TCompany[],
  companyRoles: TCompanyRole[]
) => {
  const sortingRoles = getSortAscending([...companyRoles], "role");
  return sortingRoles
    .map((companyRole, index) => {
      const company = companies.find(
        (company) => company.id === companyRole.companyId
      );
      if (sortingRoles[index + 1]?.role !== sortingRoles[index]?.role) {
        return `${company?.name} (${companyRole.role})`;
      } else {
        return company?.name;
      }
    })
    .join(", ");
};

export const getCompaniesFromIds = (
  companies: TCompany[],
  companyRoles: TCompanyRole[]
) => {
  const companiesToReturn = [] as unknown as TCompanyWithRole[];
  companyRoles.forEach((companyRole) => {
    const company = companies.find(
      (company) => company.id === companyRole.companyId
    );
    if (company) {
      companiesToReturn.push({ ...company, role: companyRole.role });
    }
  });
  return companiesToReturn;
};
