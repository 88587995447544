import { Box, Stack, Typography } from "@mui/material";
import { FC, useMemo } from "react";
import { TResult } from "../../hooks/useDocumentListWithData";
import {
  TDocument,
  TUserFullInfo,
  TVerificationRequestStatistic,
} from "../../models";
import { ActionPanel } from "../ActionPanel";
import { DocumentInfoCard } from "../DocumentInfoCard";
import { DuplicatesPanel } from "../DuplicatesPanel";
import { LeftColumn, RightColumn } from "./styles";
import { DatabaseScreeningPanel } from "../DatabaseScreeningPanel";
import { StatisticPanel } from "../StatisticPanel";
import { useWidth } from "../../../app/hooks/useWidth";
import { useRegexList } from "../../../regexConfig/hooks/useRegexList";
import { getSortDescending } from "../../utils/getSort";
import { useAuth } from "../../../../auth/useAuth";

export const PageVerificationContent: FC<{
  data: any;
  documents: TResult;
  statistic: TVerificationRequestStatistic[] | null;
  onAction: (value: string) => void;
  id?: string;
  loading: boolean;
  comments: any;
  userInfo: TUserFullInfo;
  addComment: (userDocumentId: string, comment: string) => void;
}> = ({
  data,
  documents,
  statistic,
  onAction,
  id,
  loading,
  comments,
  addComment,
  userInfo,
}) => {
  const width = useWidth();
  const [countriesRegex] = useRegexList(true);
  const { companyRole } = useAuth();
  const verificationNew = useMemo(() => {
    if (!documents?.length) {
      return {
        data: [
          {
            dataBase64: "",
            dataId: "UserInformation",
            dataLink: "",
            document: "UserInformation",
            id: "newVerify",
            type: "binary",
            verificationDocument: "UserInformation",
            data: {
              email: userInfo.email || data?.email,
              phone: userInfo.phone,
            },
          },
        ],
        document: {
          documentId: "UserInformation",
          documentType: "UserInformation",
          declineReason: [],
        },
      } as TDocument;
    }
  }, [data, documents?.length, userInfo]);

  return (
    <>
      {["xs"].includes(width) ? (
        <RightColumn>
          <ActionPanel
            companyRole={companyRole}
            statusReason={data?.statusReason}
            loading={loading}
            status={data?.status}
            onAction={onAction}
          />
          <DatabaseScreeningPanel isMobile data={data} />
          <DuplicatesPanel />
        </RightColumn>
      ) : null}
      <Box sx={{ margin: "32px 0 24px" }}>
        <Typography
          variant='h5'
          sx={{
            fontWeight: "800",
            letterSpacing: "0.5px",
            color: "#1C1F21",
          }}
        >
          Applicant data
        </Typography>
      </Box>
      <Box width='100%' paddingBottom='40px'>
        <Stack
          direction='row'
          justifyContent='center'
          alignItems='flex-start'
          spacing={2}
        >
          <LeftColumn
            sx={{
              flexGrow: 2,
            }}
          >
            <StatisticPanel data={data} statistic={statistic} />
            {documents?.length ? (
              getSortDescending(documents, ["document", "documentId"], {
                condition: (comparedDocument) =>
                  !["Passport", "Selfie", "Id"].includes(
                    comparedDocument.document.documentType
                  ),
                type: "number",
              })?.map(({ document, data }: TDocument) => (
                <DocumentInfoCard
                  companyRole={companyRole}
                  isMobile={["xs", "sm"].includes(width)}
                  userInfo={userInfo}
                  addComment={addComment}
                  initialComment={comments[document.documentId]}
                  key={document.documentId}
                  requestId={id!}
                  document={document}
                  data={data}
                />
              ))
            ) : (
              <DocumentInfoCard
                companyRole={companyRole}
                isMobile={["xs", "sm"].includes(width)}
                userInfo={userInfo}
                addComment={addComment}
                initialComment={comments[verificationNew!.document.documentId]}
                key={"newVerification"}
                requestId={id!}
                data={verificationNew!.data}
                document={verificationNew!.document}
              />
            )}
          </LeftColumn>
          {!["xs"].includes(width) ? (
            <RightColumn>
              <ActionPanel
                companyRole={companyRole}
                statusReason={data?.statusReason}
                loading={loading}
                status={data?.status}
                onAction={onAction}
              />
              <DatabaseScreeningPanel data={data} />
              <DuplicatesPanel />
            </RightColumn>
          ) : null}
        </Stack>
      </Box>
    </>
  );
};
