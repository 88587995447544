import { useCallback, useEffect, useState } from "react";
import {
  Button,
  TextField,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  Alert,
  AlertColor,
  Grid,
} from "@mui/material";
import {
  changeUserIdToId,
  checkOfficerPhone,
  TCompanyRole,
  TCreateOfficerData,
} from "../../network";
import {
  IOfficerActionModeType,
  TCompany,
  TCompanyWithRole,
  TOfficer,
} from "../../models";
import { TPagingResponse } from "../../../../models/PagingResponse";
import { getCompaniesFromIds } from "../../utils";
import { useOfficerAction } from "../../hooks/useOfficerAction";
import { isEmpty } from "lodash";
import { filterInput, numbersRegExp } from "../../../../utils/validate";
import { DoubleSelectDialog } from "../../../ui/components/MuiSelectDialog";

export type Props = {
  onClose: (success: boolean) => void;
  open: boolean;
  companiesList: TPagingResponse<TCompany>;
  selectedOfficer: TOfficer | null;
  setSelectedOfficer: React.Dispatch<React.SetStateAction<TOfficer | null>>;
  actionMode?: IOfficerActionModeType;
  setActionMode: React.Dispatch<React.SetStateAction<IOfficerActionModeType>>;
};

type FormValues = {
  email: string;
  phone: string;
  companyRole: TCompanyRole[];
};

type TFormInfo = {
  severity: AlertColor;
  text: string;
};

const actionsView = {
  create: "Create",
  edit: "Edit",
  onlyEdit: "Edit",
};

export const CreateOfficerDialog = (props: Props) => {
  const {
    onClose,
    open,
    companiesList,
    actionMode = "create",
    setActionMode,
    setSelectedOfficer,
    selectedOfficer,
  } = props;
  const [result, loading, error, request, clearError] = useOfficerAction();
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [isPhoneChecked, setIsPhoneChecked] = useState(false);
  const [companies, setCompanies] = useState<TCompanyWithRole[] | []>([]);
  const [errors, setErrors] = useState<any>({});
  const [info, setInfo] = useState<TFormInfo>({ severity: "info", text: "" });

  const handleCancel = useCallback(
    (success: boolean = false) => {
      setEmail("");
      setPhone("");
      setCompanies([]);
      setErrors({});
      clearError();
      setIsPhoneChecked(false);
      setInfo({ severity: "info", text: "" });
      onClose(success);
    },
    [onClose, clearError]
  );

  // useEffect(() => {
  //   getUserData();
  // }, []);

  useEffect(() => {
    if (result) {
      handleCancel(true);
    }
  }, [result, handleCancel]);

  const handleChangePhone = (phone: string) => {
    setIsPhoneChecked(false);
    setEmail("");
    setCompanies([]);
    setInfo({ severity: "info", text: "" });
    setPhone(phone);
  };

  useEffect(() => {
    if (selectedOfficer) {
      setIsPhoneChecked(true);
      setEmail(selectedOfficer.email);
      setPhone(selectedOfficer.phone);
      setCompanies(
        getCompaniesFromIds(companiesList.items, selectedOfficer.companyRole)
      );
    }
  }, [selectedOfficer, companiesList]);

  const checkUserPhone = async () => {
    try {
      let phoneNumber = "+" + phone.split("+").join("");
      const data = await checkOfficerPhone(phoneNumber);
      setSelectedOfficer(changeUserIdToId(data));
      if (data) {
        setInfo({
          severity: "info",
          text: "User with this number is already exist. You can edit it",
        });
        setActionMode("edit");
        setEmail(data.email);
        setCompanies(
          getCompaniesFromIds(companiesList.items, data.companyRole)
        );
      }
    } catch (e: any) {
      if (e.message.includes("sql get user by phone error")) {
        setActionMode("create");
        setEmail("");
        setCompanies([]);
        setInfo({
          severity: "success",
          text: "You can create new user with this number",
        });
      } else {
        setErrors({ network: e.message });
      }
    } finally {
      setIsPhoneChecked(true);
    }
  };

  const validate = useCallback((formData: FormValues) => {
    const errors: any = {};

    if (!formData.email) {
      errors.email = true;
    }
    if (!formData.phone) {
      errors.phone = true;
    }
    setErrors(errors);
    return errors;
  }, []);

  const onSubmit = useCallback(async () => {
    const formValues: FormValues = {
      email,
      phone,
      companyRole: companies.map((company) => ({
        companyId: company.id,
        role: company.role,
      })),
    };
    const validateErrors = validate(formValues);
    if (isEmpty(validateErrors)) {
      const requestData: TCreateOfficerData = formValues;
      if (selectedOfficer) {
        request(requestData, actionMode, selectedOfficer.id);
      } else {
        request(requestData, actionMode);
      }
    }
  }, [actionMode, companies, email, phone, request, selectedOfficer, validate]);

  return (
    <Dialog
      sx={{ "& .MuiDialog-paper": { width: "80%" } }}
      maxWidth='xs'
      open={open}
      id='create-company-dialog'
      keepMounted
    >
      <DialogTitle sx={{ textTransform: "capitalize" }}>
        {actionsView[actionMode]} compliance officer
      </DialogTitle>
      <DialogContent dividers>
        <Grid container direction='column'>
          <TextField
            disabled={actionMode === "onlyEdit"}
            error={errors.phone}
            label='Phone number'
            name='phone'
            required={true}
            size='small'
            type='tel'
            margin='normal'
            value={phone}
            inputProps={{
              onChange: (e: any) => {
                filterInput(
                  (e.target as HTMLTextAreaElement).value,
                  numbersRegExp,
                  handleChangePhone
                );
                delete errors.phone;
              },
            }}
          />
          {actionMode !== "onlyEdit" ? (
            <Button disabled={!phone} onClick={checkUserPhone}>
              Check phone
            </Button>
          ) : null}
          {info.text && <Alert severity={info.severity}>{info.text}</Alert>}
          <TextField
            disabled={!isPhoneChecked}
            error={errors.email}
            label='Email'
            name='email'
            type='email'
            margin='normal'
            required={true}
            size='small'
            value={email}
            inputProps={{
              onChange: (e: any) => {
                delete errors.email;
                return setEmail(e.target.value);
              },
            }}
          />
          <DoubleSelectDialog
            sx={{ marginTop: "8px" }}
            subData={["admin", "viewer"]}
            disabled={!isPhoneChecked}
            nameField='name'
            data={companiesList.items}
            label='Companies/Role'
            name='companyRole'
            onChange={setCompanies}
            values={companies}
          />
        </Grid>
        {(error || errors.network) && (
          <Alert sx={{ marginTop: 2 }} severity='error'>
            {(error || errors.network).toString()}
          </Alert>
        )}
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={() => handleCancel()} disabled={loading}>
          Cancel
        </Button>
        <Button onClick={onSubmit} disabled={loading}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};
