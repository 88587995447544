import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import { FC, useEffect, useState } from "react";
import { titleCase } from "../../../../utils";

export const DoubleSelectDialog: FC<{
  data: any[];
  subData: any[];
  name: string;
  label: string;
  onChange: any;
  values: any;
  valueField?: string;
  nameField?: string;
  MenuProps?: any;
  [x: string]: any;
}> = ({
  data,
  subData,
  name,
  label,
  onChange,
  values,
  valueField,
  nameField,
  setValue,
  ...other
}) => {
  const [open, setOpen] = useState(false);
  const [openSubDialog, setOpenSubDialog] = useState(false);
  const [tempValue, setTempValue] = useState<any>(null);
  const getValueField = (elem: any) => (valueField ? elem[valueField] : elem);
  const getNameField = (elem: any) => (nameField ? elem[nameField] : elem);

  useEffect(() => {
    if (tempValue && !openSubDialog) {
      const selectedValue = values.find(
        (value: any) => value.id === tempValue.id
      );

      if (selectedValue) {
        const newValues = values.filter(
          (value: any) => value !== selectedValue
        );
        if (tempValue.role) {
          newValues.push(tempValue);
        }
        onChange(newValues);
      } else {
        if (tempValue.role) {
          values.push(tempValue);
          onChange(values);
        }
      }
      setTempValue(null);
    }
  }, [onChange, tempValue, openSubDialog]);

  return (
    <FormControl fullWidth {...other}>
      <InputLabel htmlFor='grouped-select'>{label}</InputLabel>
      <Select
        variant='filled'
        name={name}
        label={label}
        onChange={(e) => setTempValue(e.target.value)}
        value={values}
        renderValue={() =>
          values
            .map((value: any) => `${getNameField(value)} (${value.role})`)
            .join(", ")
        }
        onClick={() => {
          setTempValue(null);
          setOpenSubDialog(false);
          setOpen(true);
        }}
        open={open}
      >
        <Stack alignItems='flex-end'>
          <Button
            onClick={(e) => {
              e.stopPropagation();
              setOpen(false);
            }}
          >
            Done
          </Button>
        </Stack>
        {data.map((primaryElem, index) => {
          const checked = values.some(
            (value: any) => value.id === (primaryElem.id as string)
          );
          return (
            <MenuItem
              onClick={(e) => {
                e.stopPropagation();
                setOpenSubDialog(true);
              }}
              key={index}
              value={getValueField(primaryElem)}
            >
              <Checkbox
                onClick={(e: any) => {
                  if (!e.target.checked) {
                    e.stopPropagation();
                    setTempValue(() => ({
                      ...getValueField(primaryElem),
                      role: null,
                    }));
                  }
                }}
                size='small'
                checked={checked}
              />
              {getNameField(primaryElem)}{" "}
              {checked
                ? `(${
                    values.find((value: any) => value.id === primaryElem.id)
                      .role
                  })`
                : ""}
            </MenuItem>
          );
        })}
        <Dialog
          sx={{
            "& .MuiDialog-paper": {
              width: "200px",
            },
          }}
          maxWidth='xs'
          open={openSubDialog}
          onClose={() => setOpenSubDialog(false)}
          id='create-app-dialog'
        >
          <DialogTitle>Choose role</DialogTitle>
          <DialogContent sx={{ padding: "0 12px" }}>
            {subData.map((data) => (
              <MenuItem
                onClick={(e) => {
                  e.stopPropagation();
                  setTempValue((prev: any) => ({ ...prev, role: data }));
                  setOpenSubDialog(false);
                }}
              >
                {titleCase(data)}
              </MenuItem>
            ))}
          </DialogContent>
          <DialogActions>
            <Stack flexDirection='row' alignItems='center'>
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  setTempValue(null);
                  setOpenSubDialog(false);
                }}
              >
                Close
              </Button>
            </Stack>
          </DialogActions>
        </Dialog>
      </Select>
    </FormControl>
  );
};
