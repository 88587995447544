import { Component, createContext } from "react";
import { mainClient } from "../network/mainClient";
import { authStorage } from "./authStorage";
import { getSortAscending } from "../modules/verificationRequest/utils/getSort";
import { TCompany } from "../modules/company/models";
import { userLogout } from "../network/mainClient/auth";
import { TokenData } from "./types";

export type AuthState = {
  loading: boolean;
  loaded: boolean;
  authenticated: boolean;
  companyRole: string;
  authorizedCompanyId: string | null;
  companies: { items: TCompany[] } | null;
};

export type AuthContextValue = AuthState & {
  requestSms: typeof mainClient.auth.requestSms;
  authWithCode: typeof mainClient.auth.authWithCode;
  // setAuthorizedCompanyId: (companyId: string) => void;
  setTokenData: (tokenData: TokenData) => void;
  logout: () => Promise<boolean>;
  setCompanies: (companies: { items: TCompany[] } | null) => void;
  setCompanyRole: (role: "viewer" | "admin") => void;
};

export const AuthContext = createContext<AuthContextValue>(
  {} as AuthContextValue
);

export class AuthProvider extends Component<{}, AuthState> {
  state = {
    loading: true,
    loaded: false,
    authenticated: false,
    authorizedCompanyId: null,
    companies: null,
    companyRole: "viewer",
  };

  componentDidMount() {
    this.restore();
  }

  setCompanyRole = (companyRole: "viewer" | "admin") => {
    this.setState({
      companyRole,
    });
  };

  restore = async () => {
    const data = await authStorage.getAuthData();
    this.setState({
      loading: false,
      loaded: true,
      authenticated: data !== null,
      authorizedCompanyId: data && data.companyId ? data.companyId : null,
      companies: null,
    });
  };

  requestSms = async (phone: string) => {
    return await mainClient.auth.requestSms(phone);
  };

  authWithCode = async (phone: string, code: string, salt: string) => {
    const result = await mainClient.auth.authWithCode(phone, code, salt);
    authStorage.setAuthData(result);
    this.setState({
      authenticated: true,
    });
    return result;
  };

  logout = async () => {
    // await userLogout();
    authStorage.clear();
    this.setState({
      authenticated: false,
      authorizedCompanyId: null,
    });
    return true;
  };

  setTokenData = (tokenData: TokenData) => {
    if (!tokenData) {
      throw new Error("Token Data not provided");
    }
    authStorage.setAuthData(tokenData);
    this.setState({
      authorizedCompanyId: tokenData.companyId || null,
    });
  };

  // setAuthorizedCompanyId = (companyId: string) => {
  //   this.setState({
  //     authorizedCompanyId: companyId || null,
  //   });
  // };

  setCompanies = (data: any | null) => {
    this.setState({
      companies: { items: getSortAscending(data.items, "name") },
    });
  };

  render() {
    const { children } = this.props;

    const contextValue: AuthContextValue = {
      ...this.state,
      requestSms: this.requestSms,
      setCompanyRole: this.setCompanyRole,
      authWithCode: this.authWithCode,
      setTokenData: this.setTokenData,
      // setAuthorizedCompanyId: this.setAuthorizedCompanyId,
      setCompanies: this.setCompanies,
      logout: this.logout,
    };

    return (
      <AuthContext.Provider value={contextValue}>
        {children}
      </AuthContext.Provider>
    );
  }
}
